import Vue from 'vue';
import Vuex from 'vuex';

import Account from './modules/Account';
import Advertiser from './modules/Advertiser';
import User from './modules/User';
import Creative from './modules/Creative';
import Campaign from './modules/Campaign';
import Report from './modules/Report';
import Financial from './modules/Financial';
import Channel from './modules/Channel';
import Affiliate from './modules/Affiliate';
import AffiliateReports from './modules/AffiliateReports';
import ComissionSchedule from './modules/ComissionSchedule';
import AdvertiserValidation from './modules/AdvertiserValidation';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reqPagination: {}
  },
  getters: {
    reqPagination: (state) => state.reqPagination
  },

  mutations: {
    SET_PAGINATION(state, payload) {
      delete payload.data;
      state.reqPagination = payload;
    }
  },
  actions: {
    ActionSetPagination({ commit }, payload) {
      commit('SET_PAGINATION', payload);
    }
  },
  modules: {
    Account,
    Advertiser,
    User,
    Creative,
    Campaign,
    Report,
    Financial,
    Channel,
    Affiliate,
    AffiliateReports,
    ComissionSchedule,
    AdvertiserValidation
  }
});
