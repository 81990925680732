export default {
    namespaced: true,
    state: {
        advertiserValidationConversions: [],
        filter: {
            campaign_id: '',
            period: null,
            status_v3: '',
            status_adv: ''
        },
        campaignValidationHistory: {}
    },
    getters: {
        advertiserValidationConversions: (state) => state.advertiserValidationConversions,
        campaignValidationHistory: (state) => state.campaignValidationHistory
    },
    mutations: {
        SET_ADVERTISER_VALIDATION_CONVERSIONS(state, payload) {
            state.advertiserValidationConversions = payload;
        },
        SET_CAMPAIGN_VALIDATION_HISTORY(state, payload) {
            state.campaignValidationHistory = payload;
        }
    },
    actions: {
        ActionSetAdvertiserValidationConversions({ commit }, payload) {
            commit('SET_ADVERTISER_VALIDATION_CONVERSIONS', payload);
        },
        ActionSetCampaignValidationHistory({ commit }, payload) {
            commit('SET_CAMPAIGN_VALIDATION_HISTORY', payload);
        }
    }
};
